import { EventNamesMap } from './eventNames';
export async function encodeToSHA256(input) {
    if (!input || !crypto.subtle) {
        return;
    }
    const encoder = new TextEncoder();
    const bufferData = encoder.encode(input);
    const hashBuffer = await crypto.subtle.digest('SHA-256', bufferData);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((b) => {
        return b.toString(16).padStart(2, '0');
    })
        .join('');
    return hashHex;
}
function roundQuantities(quantity = 1) {
    return {
        roundedQuantity: Math.round(quantity),
        quantityBeforeRounding: quantity,
    };
}
export function mapDataToCAPIData(eventData) {
    const contents = [];
    const contentIdsOverridden = !!eventData.ecommerce?.contentIdsOverride?.length;
    const contentIds = contentIdsOverridden
        ? eventData.ecommerce.contentIdsOverride
        : [];
    const currency = eventData.ecommerce?.items?.find((it) => {
        return it.currency;
    })?.currency;
    eventData.ecommerce?.items?.forEach((it) => {
        if (it?.codeShort) {
            const { roundedQuantity } = roundQuantities(it?.quantity);
            if (!contentIdsOverridden) {
                contentIds.push(it.codeShort);
            }
            if (!it.currency) {
                console.error('No currency in analytics event:', eventData.eventId, JSON.stringify(it));
            }
            contents.push({
                category: it.categoryCodes?.join(', '),
                itemPrice: getPrice(it.totalPrice),
                productId: it?.codeShort,
                quantity: roundedQuantity,
                title: it?.name,
            });
        }
    });
    const value = getPrice(eventData.ecommerce?.value);
    const result = {
        eventId: eventData.eventId,
        eventName: EventNamesMap[eventData.event].capi,
        customData: {
            contentIds,
            ...(eventData.type ? { contentCategory: eventData.type } : {}),
            ...(eventData.title ? { contentName: eventData.title } : {}),
            ...(value === undefined ? {} : { value }),
            currency,
            contents,
        },
    };
    return result;
}
function getPrice(price) {
    return price?.withoutTax ?? price;
}
export function mapDataToGTMData(eventData) {
    let contentIds = [];
    const gtmItems = [];
    let currency;
    if (eventData.ecommerce?.items) {
        eventData.ecommerce.items?.forEach((it) => {
            const { roundedQuantity, quantityBeforeRounding } = roundQuantities(it?.quantity);
            const price = getPrice(it.totalPrice);
            currency = currency || it.currency;
            const gtmItem = {
                ...(it.coupons?.length ? { coupon: it.coupons.join(', ') } : {}),
                ...(price || price === 0 ? { price: price } : {}),
                ...(it.name ? { item_name: it.name } : {}),
                ...(it.codeShort ? { item_id: it.codeShort } : {}),
                ...(it.categoryCodes?.length
                    ? { item_category: it.categoryCodes.join(', ') }
                    : {}),
                ...(roundedQuantity ? { quantity: roundedQuantity } : {}),
                ...(quantityBeforeRounding
                    ? { quantityBeforeRounding: quantityBeforeRounding }
                    : {}),
            };
            if (it.codeShort) {
                contentIds.push(it.codeShort);
            }
            gtmItems.push(gtmItem);
        });
    }
    if (eventData.ecommerce?.contentIdsOverride?.length) {
        contentIds = eventData.ecommerce.contentIdsOverride;
        delete eventData.ecommerce.contentIdsOverride;
    }
    const value = getPrice(eventData.ecommerce?.value);
    const finalGtmData = {
        ...eventData,
        event: EventNamesMap[eventData.event].gtm,
        ...(eventData.ecommerce?.items?.length
            ? {
                ecommerce: {
                    ...eventData.ecommerce,
                    ...(currency && { currency }),
                    ...(eventData.ecommerce.value ? { value } : {}),
                    ...(eventData.ecommerce.coupons?.length
                        ? { coupon: eventData.ecommerce.coupons.join(', ') }
                        : {}),
                    contentIds,
                    items: gtmItems,
                },
            }
            : {}),
    };
    delete finalGtmData.userData;
    delete finalGtmData.ecommerce?.coupons;
    return finalGtmData;
}
export const CLICK_ID_COOKIE_NAME = '_fbc';
export const FBP_COOKIE_NAME = '_fbp';
export const FALLBACK_EXTERNAL_ID_COOKIE_NAME = 'externalID';
export function mapCartDataToEvents(cartData) {
    return {
        totalPrice: cartData?.totalPrice?.withoutTax,
        items: cartData?.items?.map((item) => {
            return mapCartDataItemToEvents(item);
        }),
    };
}
export function extractCategoryCodes(categories) {
    return (categories || []).reduce((validCategoryCodes, category) => {
        const categoryCode = category?.referencedEntity?.attributes?.code;
        if (categoryCode) {
            validCategoryCodes.push(categoryCode);
        }
        return validCategoryCodes;
    }, []);
}
export function mapCartDataItemToEvents(item) {
    return {
        categoryCodes: extractCategoryCodes(item?.product?.categories),
        codeShort: item?.product?.attributes?.codeShort,
        currency: item?.product?.priceForSale?.currency,
        id: item?.id,
        name: item?.product?.attributes?.name,
        quantity: item?.quantity,
        totalPrice: item?.totalPrice,
    };
}
function extractPriceWithoutTax(item) {
    const priceAsNumber = Number(item?.priceForSale?.priceWithoutTax);
    return isNaN(priceAsNumber) ? undefined : priceAsNumber;
}
export function mapProductToEvents(product, useCodeShortOfVariants = false) {
    const price = extractPriceWithoutTax(product);
    return {
        name: product?.attributes?.name,
        codeShort: product?.attributes?.codeShort,
        currency: product?.priceForSale?.currency,
        ...(useCodeShortOfVariants && getCodeShortsOfVariants(product)),
        categoryCodes: extractCategoryCodes(product?.categories),
        totalPrice: {
            // NOTE: product?.attributes?.saleEnded means it is an obsolete product, make the price show 0
            withoutTax: product?.attributes?.saleEnded ? 0 : price,
        },
    };
}
function getCodeShortsOfVariants(item) {
    return {
        codeShortOfVariants: item?.variants?.map((it) => {
            return it?.referencedEntity?.attributes?.codeShort;
        }) || [],
    };
}
