import { gql } from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client/react/hooks';
const defaultOptions = {};
export const ProductForAnalyticsEventsFieldsBaseFragmentDoc = gql `
    fragment productForAnalyticsEventsFieldsBase on Product {
  variants {
    referencedEntity {
      attributes {
        codeShort
      }
    }
  }
  priceForSale(validNow: true, currency: $currency) {
    currency
    priceWithoutTax
  }
}
    `;
export const ProductForAnalyticsEventsFieldsCommonFragmentDoc = gql `
    fragment productForAnalyticsEventsFieldsCommon on Product {
  ...productForAnalyticsEventsFieldsBase
  categories {
    referencedEntity {
      attributes {
        code
      }
    }
  }
}
    ${ProductForAnalyticsEventsFieldsBaseFragmentDoc}`;
export const ProductForAnalyticsEventsFieldsFragmentDoc = gql `
    fragment productForAnalyticsEventsFields on Product {
  attributes(locale: $locale) {
    name
    codeShort
    saleEnded
  }
  ...productForAnalyticsEventsFieldsCommon
}
    ${ProductForAnalyticsEventsFieldsCommonFragmentDoc}`;
export const FieldsPriceFragmentDoc = gql `
    fragment fieldsPrice on PriceForSale {
  priceWithTax
  priceWithoutTax
  currency
  validity
}
    `;
export const ProductPricesFieldsFragmentDoc = gql `
    fragment productPricesFields on Product {
  multiplePricesForSaleAvailable(currency: $currency)
  priceForSale(validNow: true, currency: $currency) {
    ...fieldsPrice
  }
  allPricesForSale(priceLists: $priceLists, currency: $currency) {
    accompanyingPrice(priceLists: $priceLists) {
      priceWithTax
      priceWithoutTax
      currency
      validity
    }
    ...fieldsPrice
  }
  attributes(locale: $locale) {
    saleEnded
  }
}
    ${FieldsPriceFragmentDoc}`;
export const AvailabilityFieldsFragmentDoc = gql `
    fragment availabilityFields on Product {
  stocks {
    attributes {
      quantityOnStock
    }
    referencedEntity {
      attributes(locale: $locale) {
        name
        code
      }
    }
  }
  attributes(locale: $locale) {
    availability
  }
}
    `;
export const ImageFieldsFragmentDoc = gql `
    fragment imageFields on RelatedFiles {
  fileIds
  files {
    src
    alt
  }
}
    `;
export const ProductForListingFieldsFragmentDoc = gql `
    fragment productForListingFields on Product {
  primaryKey
  associatedData {
    localization
  }
  categories {
    referencedEntity {
      primaryKey
      attributes {
        name
        url
        code
      }
    }
  }
  relatedProducts {
    attributes {
      category
    }
  }
  ...productForAnalyticsEventsFields
  ...productPricesFields
  ...availabilityFields
  attributes(locale: $locale) {
    name
    descriptionShort
    url
    productType
    ratingVotes
    rating
    motive: relatedFiles(category: "hlavni-motiv") {
      ...imageFields
    }
    minOrderQuantity
    saleEnded
  }
  master {
    referencedPrimaryKey
  }
  tags {
    referencedEntity {
      primaryKey
      attributes {
        name
        code
        isVisibleInDetail
        isVisibleInFilter
        isVisibleInListing
      }
      type
    }
  }
  parameterValues(
    filterBy: {inScope: {scope: LIVE, filtering: {attributeVariantEquals: true}}}
  ) {
    referencedEntity {
      primaryKey
      attributes {
        name
        code
        order
      }
      parameter {
        attributes {
          orderInParameter
        }
        referencedEntity {
          attributes {
            name
            code
            isVisibleInDetail
          }
        }
      }
    }
  }
}
    ${ProductForAnalyticsEventsFieldsFragmentDoc}
${ProductPricesFieldsFragmentDoc}
${AvailabilityFieldsFragmentDoc}
${ImageFieldsFragmentDoc}`;
export const RecordStripFieldsFragmentDoc = gql `
    fragment recordStripFields on ProductRecordStrip {
  first
  empty
  limit
  totalRecordCount
  offset
  data {
    ...productForListingFields
  }
}
    ${ProductForListingFieldsFragmentDoc}`;
export const ProductComparisonAddItemDocument = gql `
    mutation productComparisonAddItem($input: ProductComparisonItemInput) {
  productComparisonAddItem(input: $input) {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useProductComparisonAddItemMutation__
 *
 * To run a mutation, you first call `useProductComparisonAddItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonAddItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productComparisonAddItemMutation, { data, loading, error }] = useProductComparisonAddItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductComparisonAddItemMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ProductComparisonAddItemDocument, options);
}
export const ProductComparisonAddItemsDocument = gql `
    mutation productComparisonAddItems($input: ProductComparisonItemInput) {
  productComparisonAddItems(input: $input) {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useProductComparisonAddItemsMutation__
 *
 * To run a mutation, you first call `useProductComparisonAddItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonAddItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productComparisonAddItemsMutation, { data, loading, error }] = useProductComparisonAddItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductComparisonAddItemsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ProductComparisonAddItemsDocument, options);
}
export const ProductComparisonRemoveItemDocument = gql `
    mutation productComparisonRemoveItem($input: ProductComparisonItemInput) {
  productComparisonRemoveItem(input: $input) {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useProductComparisonRemoveItemMutation__
 *
 * To run a mutation, you first call `useProductComparisonRemoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonRemoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productComparisonRemoveItemMutation, { data, loading, error }] = useProductComparisonRemoveItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductComparisonRemoveItemMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ProductComparisonRemoveItemDocument, options);
}
export const ProductComparisonRemoveAllItemsDocument = gql `
    mutation productComparisonRemoveAllItems {
  productComparisonRemoveAllItems {
    error {
      message
      messageCode
    }
    status
  }
}
    `;
/**
 * __useProductComparisonRemoveAllItemsMutation__
 *
 * To run a mutation, you first call `useProductComparisonRemoveAllItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonRemoveAllItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productComparisonRemoveAllItemsMutation, { data, loading, error }] = useProductComparisonRemoveAllItemsMutation({
 *   variables: {
 *   },
 * });
 */
export function useProductComparisonRemoveAllItemsMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ProductComparisonRemoveAllItemsDocument, options);
}
export const ProductComparisonShareEmailDocument = gql `
    mutation productComparisonShareEmail($args: ProductComparisonArgs, $input: ProductComparisonShareEmailInput) {
  productComparisonShareEmail(args: $args, input: $input)
}
    `;
/**
 * __useProductComparisonShareEmailMutation__
 *
 * To run a mutation, you first call `useProductComparisonShareEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonShareEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productComparisonShareEmailMutation, { data, loading, error }] = useProductComparisonShareEmailMutation({
 *   variables: {
 *      args: // value for 'args'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductComparisonShareEmailMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useMutation(ProductComparisonShareEmailDocument, options);
}
export const ProductComparisonGetItemsDocument = gql `
    query productComparisonGetItems($locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  productComparisonGetItems {
    recordStrip(offset: 0, limit: 150) {
      ...recordStripFields
    }
  }
}
    ${RecordStripFieldsFragmentDoc}`;
/**
 * __useProductComparisonGetItemsQuery__
 *
 * To run a query within a React component, call `useProductComparisonGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductComparisonGetItemsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useProductComparisonGetItemsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(ProductComparisonGetItemsDocument, options);
}
export function useProductComparisonGetItemsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(ProductComparisonGetItemsDocument, options);
}
export function useProductComparisonGetItemsSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(ProductComparisonGetItemsDocument, options);
}
export const ProductComparisonGetShareDocument = gql `
    query productComparisonGetShare {
  productComparisonGetShare
}
    `;
/**
 * __useProductComparisonGetShareQuery__
 *
 * To run a query within a React component, call `useProductComparisonGetShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonGetShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductComparisonGetShareQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductComparisonGetShareQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(ProductComparisonGetShareDocument, options);
}
export function useProductComparisonGetShareLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(ProductComparisonGetShareDocument, options);
}
export function useProductComparisonGetShareSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(ProductComparisonGetShareDocument, options);
}
export const ProductComparisonGetShareDataDocument = gql `
    query productComparisonGetShareData($args: ProductComparisonArgs, $locale: CatalogLocale, $currency: CatalogCurrency, $priceLists: [String!]! = ["reference"]) {
  productComparisonGetShareData(args: $args) {
    recordStrip(offset: 0, limit: 200) {
      ...recordStripFields
    }
  }
}
    ${RecordStripFieldsFragmentDoc}`;
/**
 * __useProductComparisonGetShareDataQuery__
 *
 * To run a query within a React component, call `useProductComparisonGetShareDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductComparisonGetShareDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductComparisonGetShareDataQuery({
 *   variables: {
 *      args: // value for 'args'
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      priceLists: // value for 'priceLists'
 *   },
 * });
 */
export function useProductComparisonGetShareDataQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useQuery(ProductComparisonGetShareDataDocument, options);
}
export function useProductComparisonGetShareDataLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useLazyQuery(ProductComparisonGetShareDataDocument, options);
}
export function useProductComparisonGetShareDataSuspenseQuery(baseOptions) {
    const options = baseOptions === ApolloReactHooks.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
    return ApolloReactHooks.useSuspenseQuery(ProductComparisonGetShareDataDocument, options);
}
import { getGqlData } from '@edeeone/juan-core/graphql/getGqlData';
export const getData_ProductComparisonGetItemsQuery = async function (options) {
    return getGqlData({
        ...options,
        query: ProductComparisonGetItemsDocument,
    });
};
export const getData_ProductComparisonGetShareQuery = async function (options) {
    return getGqlData({
        ...options,
        query: ProductComparisonGetShareDocument,
    });
};
export const getData_ProductComparisonGetShareDataQuery = async function (options) {
    return getGqlData({
        ...options,
        query: ProductComparisonGetShareDataDocument,
    });
};
