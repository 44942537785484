export var RootEventNames;
(function (RootEventNames) {
    RootEventNames["addPaymentInfo"] = "addPaymentInfo";
    RootEventNames["addShippingInfo"] = "addShippingInfo";
    RootEventNames["addToCart"] = "addToCart";
    RootEventNames["addToWishlist"] = "addToWishlist";
    RootEventNames["beginCheckout"] = "beginCheckout";
    RootEventNames["fgPageView"] = "fgPageView";
    RootEventNames["form"] = "form";
    RootEventNames["lead"] = "lead";
    RootEventNames["purchase"] = "purchase";
    RootEventNames["removeFromCart"] = "removeFromCart";
    RootEventNames["selectItem"] = "selectItem";
    RootEventNames["viewCart"] = "viewCart";
    RootEventNames["viewContent"] = "viewContent";
    RootEventNames["viewItem"] = "viewItem";
})(RootEventNames || (RootEventNames = {}));
// Define a dictionary for all Google Tag event mappings
export const EventNamesMap = {
    addPaymentInfo: {
        gtm: 'add_payment_info',
        capi: 'AddPaymentInfo',
    },
    addShippingInfo: {
        // custom event
        gtm: 'add_shipping_info',
        capi: 'AddShippingInfo',
    },
    addToCart: {
        gtm: 'add_to_cart',
        capi: 'AddToCart',
    },
    addToWishlist: {
        gtm: 'add_to_wishlist',
        capi: 'AddToWishlist',
    },
    beginCheckout: {
        gtm: 'begin_checkout',
        capi: 'InitiateCheckout',
    },
    fgPageView: {
        // custom event
        gtm: 'fg_page_view',
        capi: 'FgPageView',
    },
    form: {
        gtm: 'form',
        capi: 'Lead',
    },
    lead: {
        gtm: 'lead',
        capi: 'CompleteRegistration',
    },
    purchase: {
        gtm: 'purchase',
        capi: 'Purchase',
    },
    removeFromCart: {
        // custom event
        gtm: 'remove_from_cart',
        capi: 'RemoveFromCart',
    },
    selectItem: {
        // custom event
        gtm: 'select_item',
        capi: 'SelectItem',
    },
    viewCart: {
        // custom event
        gtm: 'view_cart',
        capi: 'ViewCart',
    },
    viewItem: {
        gtm: 'view_item',
        capi: 'ViewContent',
    },
    viewContent: {
        // custom event
        gtm: 'view_content',
        capi: 'ViewContent',
    },
};
